<app-km-header></app-km-header>
<div class="d-flex flex-column justify-content-center align-items-center m-0">
  <div class="text-start justify-content-center align-items-center m-5">
    <div class="row d-flex justify-content-center">
      <h1>Impressum</h1>
      <p>Angaben gemäß § 5 TMG</p>
    </div>
    <div class="row d-flex justify-content-center mb-3">
      <h4>Verantwortlich:</h4>
      <p class="m-0">Fa.Kältemeister</p>
      <p class="m-0">Manfred-von-Ardenne-Ring 20 Haus D</p>
      <p class="m-0">01099 Dresden</p>
    </div>
    <div class="row d-flex justify-content-center mb-3">
      <h4>Geschäftsführender:</h4>
      <p class="m-0">Herr Falk Herrmann</p>
    </div>
    <div class="row d-flex justify-content-center mb-3">
      <h4>Kontakt:</h4>
      <p class="m-0">Telefon: 0351/8925240</p>
      <p class="m-0">Herr Herrmann: 0162/1892166</p>
      <p class="m-0">Email: info&#64;kaeltemeister.de</p>
    </div>
    <div class="row d-flex justify-content-center mb-3">
      <h4>Daten Finanzamt:</h4>
      <p class="m-0">Steuernummer: 202/230/12885</p>
      <p class="m-0">Ust.-ID: DE354637553</p>
    </div>
    <div class="row d-flex justify-content-center mb-3">
      <h4>Kammerzugehörigkeit:</h4>
      <p class="m-0">Handwerkskammer Dresden</p>
      <p class="m-0">Mitgliedsnummer: 0428368</p>
    </div>
    <div class="row d-flex justify-content-center mb-3">
      <h4>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h4>
      <p class="m-0">Kältemeister</p>
      <p class="m-0">Inhaber: Falk Herrmann</p>
      <p class="m-0">Manfred-von-Ardenne-Ring-20 Haus D</p>
      <p class="m-0">01099 Dresden</p>
      <p class="mt-3 m-0">Hinweis auf EU-Streitschlichtung</p>
      <p class="m-0">Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
        <a
          href="http://ec.europa.eu/consumers/odr"
          title="Plattform zur Online-Streitbeilegung besuchen"
          target="_blank"
          rel="noopener noreferrer"
        >http://ec.europa.eu/consumers/odr</a>
      </p>
      <p class="m-0">Unsere E-Mail-Adresse finden sie oben im Impressum.</p>
    </div>
    <div class="row d-flex justify-content-center mb-3">
      <h5>Haftungsausschluss (Disclaimer)</h5>
      <h5 class="mt-3 m-0">Haftung für Inhalte</h5>
      <p class="m-0">
        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
        verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
        gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
        hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
        bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
        Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
        entfernen.
      </p>
      <h5 class="mt-3 m-0">Haftung für Links</h5>
      <p class="m-0">
        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können
        wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der
        jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung
        auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
        permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
        nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
      </p>
      <h5 class="mt-3 m-0">Urheberrecht</h5>
      <p class="m-0">
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die
        Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen
        der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
        privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
        wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet.
        Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
        Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
      </p>
    </div>
  </div>
</div>
<app-km-footer></app-km-footer>
