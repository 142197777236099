import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MdbAccordionModule } from 'mdb-angular-ui-kit/accordion';
import { MdbCarouselModule } from 'mdb-angular-ui-kit/carousel';
import { MdbCheckboxModule } from 'mdb-angular-ui-kit/checkbox';
import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { MdbPopoverModule } from 'mdb-angular-ui-kit/popover';
import { MdbRadioModule } from 'mdb-angular-ui-kit/radio';
import { MdbRangeModule } from 'mdb-angular-ui-kit/range';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MdbScrollspyModule } from 'mdb-angular-ui-kit/scrollspy';
import { MdbTabsModule } from 'mdb-angular-ui-kit/tabs';
import { MdbTooltipModule } from 'mdb-angular-ui-kit/tooltip';
import { MdbValidationModule } from 'mdb-angular-ui-kit/validation';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KmHeaderComponent } from './km-header/km-header.component';
import { KmServicesComponent } from './km-services/km-services.component';
import { KmCompanyComponent } from './km-company/km-company.component';
import { KmPartnerComponent } from './km-partner/km-partner.component';
import { KmContactComponent } from './km-contact/km-contact.component';
import { ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ContactService } from './contact.service';
import { KmFooterComponent } from './km-footer/km-footer.component';
import { KmDataSecurityComponent } from './km-data-security/km-data-security.component';
import { KmImpressumComponent } from './km-impressum/km-impressum.component';
import { KmMainContentComponent } from './km-main-content/km-main-content.component';
import { KmNavigationComponent } from './km-navigation/km-navigation.component';
import { KmAttentionBlockComponent } from './km-attention-block/km-attention-block.component';
import { KmContactModalComponent } from './km-contact-modal/km-contact-modal.component';

@NgModule({ declarations: [
        AppComponent,
        KmHeaderComponent,
        KmServicesComponent,
        KmCompanyComponent,
        KmPartnerComponent,
        KmContactComponent,
        KmFooterComponent,
        KmDataSecurityComponent,
        KmImpressumComponent,
        KmMainContentComponent,
        KmNavigationComponent,
        KmAttentionBlockComponent,
        KmContactModalComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        MdbAccordionModule,
        MdbCarouselModule,
        MdbCheckboxModule,
        MdbCollapseModule,
        MdbDropdownModule,
        MdbFormsModule,
        MdbModalModule,
        MdbPopoverModule,
        MdbRadioModule,
        MdbRangeModule,
        MdbRippleModule,
        MdbScrollspyModule,
        MdbTabsModule,
        MdbTooltipModule,
        MdbValidationModule,
        BrowserAnimationsModule,
        ReactiveFormsModule], providers: [ContactService, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }
